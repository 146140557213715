<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        width="400"
        scrollable
    >

      <v-card>
        <v-card-title>
          Détails monétaire
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <v-list two-line v-if="money.length">
            <v-list-item v-for="(item,i) in money" :key="i">

              <v-list-item-avatar color="gifty">
                <v-icon dark>mdi-cash</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="font-weight-medium">
                  {{ item.value }} DZD
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.value * item.pivot.quantity }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action class="font-weight-bold gifty--text">
                x {{ item.pivot.quantity }}
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <div v-else class="text-center">
            <v-avatar color="grey lighten-4 mb-3">
              <v-icon>mdi-cash</v-icon>
            </v-avatar>
            <p>
              Ce paiement n'a pas de détails monétaire !
            </p>
          </div>

        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      dialog: false,
      money: [],
    }
  },
  methods: {
    open(money) {
      this.dialog = true
      this.money = money
    },
    close() {
      this.dialog = false
    },
  },
}
</script>

