<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        width="850"
        scrollable
    >

      <v-card :loading="isLoading">

        <v-card-title>
          Détail de paiement
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <v-skeleton-loader v-if="isLoading" type="article,article"/>

          <div v-else>

            <v-row class="my-5">

              <v-col>
                <v-card outlined>
                  <v-card-text>
                    <div class="d-flex justify-space-between align-center">
                      <div>
                        Recouvreur
                        <span class="d-block font-weight-medium text-no-wrap">
                         {{ recuperator }}
                         </span>
                        <v-spacer/>
                      </div>
                      <v-avatar color="gifty">
                        <v-icon dark>mdi-account-check-outline</v-icon>
                      </v-avatar>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col>
                <v-card outlined>
                  <v-card-text>
                    <div class="d-flex justify-space-between align-center">
                      <div>
                        Date
                        <span class="d-block font-weight-medium text-no-wrap">
                         {{ date }}
                         </span>
                        <v-spacer/>
                      </div>
                      <v-avatar color="gifty">
                        <v-icon dark>mdi-calendar</v-icon>
                      </v-avatar>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col>
                <v-card outlined>
                  <v-card-text>
                    <div class="d-flex justify-space-between align-center">
                      <div>
                        Montant total
                        <span class="d-block font-weight-medium text-no-wrap">
                         {{ CurrencyFormatting(sum) }} DZD
                         </span>
                        <v-spacer/>
                      </div>
                      <v-avatar color="primary">
                        <v-icon dark>mdi-cash-check</v-icon>
                      </v-avatar>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-divider/>

            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th>Nom de POS</th>
                  <th class="text-center">Ancien montant</th>
                  <th class="text-center">Montant payé</th>
                  <th class="text-center">Écart</th>
                  <th>Date de recouvrement</th>
                  <th>Raison</th>
                  <th>Source</th>
                </tr>
                </thead>
                <tbody>
                <v-tooltip bottom color="gifty">
                  <template v-slot:activator="{ on, attrs }">
                    <tr v-bind="attrs"
                        class="pointer"
                        v-on="on"
                        v-for="(item,i) in recoveries"
                        :key="i"
                        @click="$refs.detailsDialog.open(item)">

                      <td>{{ item.account.user.name }}</td>

                      <td class="text-center">
                          <span class="font-weight-medium">
                           {{ CurrencyFormatting(item.old_amount) }}
                          </span>
                      </td>

                      <td class="text-center">
                          <span class="gifty--text font-weight-medium">
                            {{ CurrencyFormatting(item.paid_amount) }}
                          </span>
                      </td>

                      <td class="text-center">
                        <div class="info--text font-weight-medium text-no-wrap">
                          {{
                          (item.paid_amount - item.old_amount) > 0 ? '+' :
                          (item.paid_amount - item.old_amount) === 0 ? '' :  '-'
                          }}{{ CurrencyFormatting(Math.abs(item.old_amount - item.paid_amount)) }}
                        </div>
                      </td>

                      <td class="text-no-wrap">
                        {{ dateFormat(item.recovery_at) }}
                      </td>

                      <td>{{ item.recovery_raison ? item.recovery_raison.name : '' }}</td>

                      <td class="text-center">
                        <v-avatar color="info" size="30">
                          <v-icon small dark v-if="item.source === 'mobile'">mdi-cellphone</v-icon>
                          <v-icon small dark v-else>mdi-desktop-mac</v-icon>
                        </v-avatar>
                      </td>

                    </tr>
                  </template>
                  <span>Cliquez pour afficher plus des détails</span>
                </v-tooltip>
                </tbody>
              </template>
            </v-simple-table>
          </div>

        </v-card-text>

      </v-card>
    </v-dialog>

    <DetailsDialog ref="detailsDialog"/>

  </div>
</template>

<script>

import {HTTP} from "@/http-common";
import DetailsDialog from "@/views/recoveries/components/DetailsDialog";
import moment from "moment";

export default {
  components: {DetailsDialog},
  data() {
    return {
      isLoading: false,
      dialog: false,
      recoveries: [],
      date: null,
      recuperator: null,
    }
  },
  methods: {
    open(item) {
      this.dialog = true
      this.showPayment(item)
    },
    close() {
      this.dialog = false
    },
    showPayment(item) {
      this.isLoading = true
      HTTP.get('/v1/recoveries/recuperators/payments/show', {
        params: {
          date: item.date,
          recuperator_id: item.recuperator_id
        }
      }).then(res => {
        this.isLoading = false
        this.recoveries = res.data.data.recoveries
        this.date = res.data.data.date
        this.recuperator = res.data.data.recuperator
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY, HH:mm');
    }
  },
  computed: {
    sum() {
      return this.recoveries.reduce((sum, item) => item.paid_amount + sum, 0);
    },
  }
}
</script>

